import React from "react";

class PostMeta extends React.Component {
  render() {
    const date = this.props.date;
    const category = this.props.category;

    return (
      <p class="post-meta">
        {date} | Alex Brinkman | {category}
      </p>
    );
  }
}

export default PostMeta;
